import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './benefits.scss';

const Benefits = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section className="affiliateprogramsec" id="benefits">
            <div className="mycontainer">
                <div className="custom-container">
                    <div className="Bbenefits">
                        <div className="toperparraa">
                            <p className="commonheading p-0">Benefits</p>
                            <h2 className="besthngd">
                                Explore the Benefits of Estate Slice NFTs for Modern Real-Estate Development
                            </h2>
                        </div>
                        <div className="onlyfor_cards">
                            {/* Card 1 */}
                            <div className="cardds mydata">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 300 }}  // Adjusting delays for smoother effect
                                    overlay={
                                        <Tooltip id="tooltip-1">
                                            <div className="tooltip-content">
                                                <div className="tooltip-data">
                                                    <img
                                                        src="/assets/cards/card1.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                    <p className="inercard_text">
                                                        Property Governance
                                                    </p>
                                                    <p className="inerpara">
                                                        Property Governance NFTs enable holders to participate in decision-making for DeFi projects and DAOs.
                                                    </p>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button
                                            variant="light"
                                            {...triggerHandler}
                                            className="d-inline-flex align-items-center iconqus"
                                        >
                                            <Image
                                                ref={ref}
                                                roundedCircle
                                                src="/assets/info-circle.svg"
                                                className="info-icon"
                                            />
                                        </Button>
                                    )}
                                </OverlayTrigger>

                                <img
                                    src="/assets/cards/card1.svg"
                                    alt="img"
                                    className="img-fluid"
                                />
                                <p className="inercard_text">Property Governance</p>
                            </div>

                            {/* Card 2 */}
                            <div className="cardds mydata">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 300 }}  // Adjusting delays for smoother effect
                                    overlay={
                                        <Tooltip id="tooltip-2">
                                            <div className="tooltip-content">
                                                <div className="tooltip-data">
                                                    <img
                                                        src="/assets/cards/card2.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                    <p className="inercard_text">
                                                        Fully On-Chain
                                                    </p>
                                                    <p className="inerpara">
                                                        Fully on-chain NFTs store artwork and metadata on the blockchain, ensuring greater permanence and control.
                                                    </p>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button
                                            variant="light"
                                            {...triggerHandler}
                                            className="d-inline-flex align-items-center iconqus"
                                        >
                                            <Image
                                                ref={ref}
                                                roundedCircle
                                                src="/assets/info-circle.svg"
                                                className="info-icon"
                                            />
                                        </Button>
                                    )}
                                </OverlayTrigger>

                                <img
                                    src="/assets/cards/card2.svg"
                                    alt="img"
                                    className="img-fluid"
                                />
                                <p className="inercard_text">Fully On-Chain</p>
                            </div>

                            {/* Card 3 */}
                            <div className="cardds mydata">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 300 }}  // Adjusting delays for smoother effect
                                    overlay={
                                        <Tooltip id="tooltip-3">
                                            <div className="tooltip-content">
                                                <div className="tooltip-data">
                                                    <img
                                                        src="/assets/cards/card3.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                    <p className="inercard_text">
                                                        DeFi Features
                                                    </p>
                                                    <p className="inerpara">
                                                        DeFi uses smart contracts and global nodes, while NFTs enhance asset verification and market liquidity.
                                                    </p>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button
                                            variant="light"
                                            {...triggerHandler}
                                            className="d-inline-flex align-items-center iconqus"
                                        >
                                            <Image
                                                ref={ref}
                                                roundedCircle
                                                src="/assets/info-circle.svg"
                                                className="info-icon"
                                            />
                                        </Button>
                                        
                                    )}
                                </OverlayTrigger>

                                <img
                                    src="/assets/cards/card3.svg"
                                    alt="img"
                                    className="img-fluid"
                                />
                                <p className="inercard_text">DeFi Features</p>
                            </div>

                            {/* Card 4 */}
                            <div className="cardds mydata">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 300 }}  // Adjusting delays for smoother effect
                                    overlay={
                                        <Tooltip id="tooltip-4">
                                            <div className="tooltip-content">
                                                <div className="tooltip-data">
                                                    <img
                                                        src="/assets/cards/card4.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                    <p className="inercard_text">
                                                        Liquid Assets
                                                    </p>
                                                    <p className="inerpara">
                                                        Estate Slice NFTs offer liquidity by allowing them to be used as collateral for borrowing or earning yields on assets like ETH and BTC.
                                                    </p>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button
                                            variant="light"
                                            {...triggerHandler}
                                            className="d-inline-flex align-items-center iconqus"
                                        >
                                            <Image
                                                ref={ref}
                                                roundedCircle
                                                src="/assets/info-circle.svg"
                                                className="info-icon"
                                            />
                                        </Button>
                                    )}
                                </OverlayTrigger>

                                <img
                                    src="/assets/cards/card4.svg"
                                    alt="img"
                                    className="img-fluid"
                                />
                                <p className="inercard_text">Liquid Assets</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
