import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import "./mint.scss";


import Navbarmint from "../landing/headermint/Navbarmint";

const Mint = () => {

  return (
    <>
      <div className="navwarperonly">
      </div>
      <div className="container-fluid  ">
        <div className="  leftrightmarginonly">
          <section className="Riyadh">
            <Navbarmint />
            <div className="tohngfsd">
              <h1 className="rigDHBTNN">Estate Slice Property NFT</h1>
              <h2>Mint your Estate Slice</h2>
            </div>
            <div className="inner_div">
              <div className="imgdivv">
                <img src="\assets\leftimg.svg" className="img-fluid mintingimggg" />
              </div>
              <div className="right_side">
                <h3 className="minnnhytf">Mint Now</h3>
                <div className="partsecond">
                  <button className="plusssdiv">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                      <path d="M5.73601 9.23606H0.640015V5.73606H5.73601V0.612061H9.26402V5.73606H14.36V9.23606H9.26402V14.3881H5.73601V9.23606Z" fill="#10312B" />
                    </svg>
                  </button>
                  <input placeholder="2" type="text" className="inputmint" />
                  <button className="plusssdiv">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                      <path d="M8.46196 4.25H0.537964V0.75H8.46196V4.25Z" fill="#10312B" />
                    </svg>
                  </button>
                </div>
                <div className="pirceinerdiv">
                  <div className="fstminbld">
                    <p className="mntgp">
                      Minting Price:
                    </p>
                    <p className="mntgpcv">
                      0.005 ETH
                    </p>
                  </div>
                  <div className="fstminbld">
                    <p className="mntgp ">
                      Minted:
                    </p>
                    <p className="mntgpcv">
                      10
                      <span className="muttedds">10,000</span>
                    </p>
                  </div>
                </div>
                <button className="commonbtnorgss">Mint Your Estate Slice</button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="cousallmodaldiv">
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModal2Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <img
                src="\assets\info\infocard.png"
                alt="img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mint;