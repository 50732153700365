import web3NoAccount from "./web3";
import NftPurchase from './nftPurchase.json'

const getContract = (abi, address, web3) => {
  const _web3 = web3;
  // console.log('_web3',_web3);
  return new _web3.eth.Contract(abi, address);
};

export const NftPurchaseContract = (address, web3) => {
  return getContract(NftPurchase, address, web3);
};
