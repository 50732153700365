import React, { useState, useEffect } from "react";
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/landing/footer/Footer.js';
import Navbar from "./components/landing/header/Navbar.js";
import Mint from "./components/mint/Mint.js";
import Join from "./components/Join/Join.js";
import Email from "./components/Email/Email.js";
import Connect from "./components/Connect/Connect.js";
import Villaa from "./components/villaa/Villaa.js";
import useEagerConnect from "./hooks/useEagerConnect";
import Duplex from "./components/Duplex/Duplex.js";
import GhanaVilla from "./components/GhanaVilla/GhanaVilla.js";
import Apartments from "./components/Apartments/Apartments.js";

function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEagerConnect();

  return (
    <>
      <ToastContainer autoClose={5000} style={{ fontSize: 14, fontWeight: 300 }} theme="light" position="top-right" />

      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/mint' element={<Mint />} />
          <Route path='/join' element={<Join />} />
          <Route path='/Email' element={<Email />} />
          <Route path='/Connect' element={<Connect />} />
          <Route path='/Villaa' element={<Villaa />} />
          <Route path='/Duplex' element={<Duplex />} />
          <Route path='/GhanaVilla' element={<GhanaVilla />} />
          <Route path='/Apartments' element={<Apartments />} />
          {/* <Route path='/Villaa/:id' element={<Villaa />} />
          <Route path='/Duplex/:id' element={<Duplex />} /> */}
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
